/* unplugin-vue-components disabled */import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
export default {
  __name: 'MTimeSecondPicker',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var value = computed({
      get: function get() {
        return props.modelValue;
      },
      set: function set(value) {
        return emit('update:modelValue', value);
      }
    });

    var onOpen = function onOpen() {
      if (!value.value) {
        value.value = moment().format('HH:mm:ss');
      }
    };

    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(flatPickr), {
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        config: {
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i:S',
          time_24hr: true,
          enableSeconds: true,
          minuteIncrement: 1,
          disableMobile: true
        },
        onOnOpen: onOpen
      }, null, 8, ["modelValue"]);
    };
  }
};