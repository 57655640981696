/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MDropdownSelect.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import _toConsumableArray from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7e51eadb"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "select-label"
};
var _hoisted_2 = {
  key: 0
};
import { Customer } from "@/js/services/baseService";
export default {
  __name: 'consultant-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default() {
        return {
          x: "0%",
          y: "0%",
          width: "100%",
          height: "100%"
        };
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var store = useStore();

    function getConsults() {
      Customer.doctorRecordEmployees({
        branch_id: store.state.auth.branchId,
        limit_group_code: undefined
      }).then(function (data) {
        state.disabled = false;
        state.options = _toConsumableArray(data.filter(function (row) {
          return row.limit_group_code !== "BR_DOC";
        }).map(function (row) {
          return {
            name: row.name,
            value: row.id
          };
        }));
      });
    }

    getConsults();
    var state = reactive({
      disabled: true,
      dropdownShow: false,
      options: []
    });
    var selectedLabels = computed(function () {
      var _props$value, _props$value$selected, _props$value$selected2;

      if (!Array.isArray((_props$value = props.value) === null || _props$value === void 0 ? void 0 : _props$value.selected)) {
        return [];
      }

      return (_props$value$selected = (_props$value$selected2 = props.value.selected) === null || _props$value$selected2 === void 0 ? void 0 : _props$value$selected2.map(function (val) {
        var option = state.options.find(function (option) {
          return option.value === val;
        });
        return option === null || option === void 0 ? void 0 : option.name;
      }).filter(function (val) {
        return !!val;
      })) !== null && _props$value$selected !== void 0 ? _props$value$selected : [];
    });

    function onChange(selected) {
      var result = selected.toSorted(function (a, b) {
        return state.options.findIndex(function (_ref2) {
          var value = _ref2.value;
          return value === a;
        }) - state.options.findIndex(function (_ref3) {
          var value = _ref3.value;
          return value === b;
        });
      });
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        selected: result
      }));
    }

    return function (_ctx, _cache) {
      var _component_MDropdownSelect = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({
          left: __props.config.x,
          top: __props.config.y,
          width: __props.config.width,
          height: __props.config.height
        }),
        class: "consultant-block"
      }, [_createVNode(_component_MDropdownSelect, {
        show: _unref(state).dropdownShow,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).dropdownShow = $event;
        }),
        "model-value": __props.value.selected,
        options: _unref(state).options,
        multiple: "",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return onChange($event);
        }),
        displayOnly: _unref(state).disabled
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [!_unref(selectedLabels).length ? (_openBlock(), _createElementBlock("span", _hoisted_2, "服務人員")) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(_unref(selectedLabels), function (label) {
            return _openBlock(), _createElementBlock("div", {
              key: label
            }, _toDisplayString(label), 1);
          }), 128))])];
        }),
        _: 1
      }, 8, ["show", "model-value", "options", "displayOnly"])], 4);
    };
  }
};